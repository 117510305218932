* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  /* background-image: url("Images/homeimg1.jpg"); */
  /* background-size: cover; */
  font-family: Urbanist, system-ui, Avenir, Helvetica, Arial, sans-serif;
  /* color-scheme: light dark; */
}



/* */



/* navbar css */

/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Sticky Navbar */
header {
  position: sticky;
  top: 0;
  background-color: black;
  z-index: 1000;
  box-shadow: 0 4px 2px -2px rgb(252, 239, 239);
}

/* Navbar Container */
.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-brand a {
  font-size: 24px;
  /* color:rgb(5, 97, 5); */
  color: rgb(54, 168, 212);
  text-decoration: none;
  font-weight: bold;
}

/* Navbar Links */
ul {
  list-style-type: none;
  display: flex;
  gap: 20px;
}

ul li {
  display: inline-block;
}

ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  padding: 10px 15px;
}

ul li a:hover {
  background-color: #444;
  border-radius: 4px;
}

@media (max-width: 320px) {
  .navbar-container {
    flex-direction: column;
    align-items: flex-start;
  }}

/* Responsive Menu */
@media (max-width: 768px) {
  .navbar-container {
    flex-direction: column;
    align-items: flex-start;
  }

  ul {
    display: none; /* Initially hidden */
    flex-direction: column;
    width: 100%;
    padding: 0;
  }

  ul li {
    width: 100%;
  }

  ul li a {
    padding: 15px;
    text-align: center;
  }

  .menu-toggle {
    display: block;
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
  }

  .menu-toggle.open + ul {
    display: flex;
  }
}

/* Styling for Login/Logout */
li a {
  font-weight: bold;
}
/* navbar close */

/* home page css */

.hero {
  
  position: relative;
  overflow: hidden;
  width: 100%;
}

.hero-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* color:rgb(22, 32, 6); */
  color: rgb(49, 49, 29);
  background-color: rgba(245, 245, 245, 0.767);
  /* background-color: rgba(255, 255, 255, 0.205); */
  text-align: center;
}

.hero-text h1 {
  font-size: 3rem;
  margin: 0;
}

.hero-text p {
  font-size: 1.7rem;
  color: black;
  font-weight: 400;
}
.btnhome{
  background-color: black;
  color: yellow;
  font-size: 1rem;
  padding: 1rem;
margin-top: 2rem;
margin-left: 1rem;
  border-radius: 1rem;
}

/* Destination Section */
.destination {
  /* background-color: black; */
  background-color: #c1d7f8;

  color: black;
  /* color: white; */
  padding: 2rem;
  text-align: center;
}

.destination h1 {
  font-size: 2.5rem;
}

.destination p {
  font-family: cursive;
  font-size: 1rem;
  color: black;
  /* color: rgb(91, 155, 180); */
}

.first-des, .first-des-reverse {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.first-des .des-text, .first-des-reverse .des-text {
  flex: 1;
  padding: 1rem;
  text-align: left;
}

.first-des .image, .first-des-reverse .image {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.first-des .first-des-reverse  {
  width: 100%;
  max-width: 500px;
  height: auto;
  object-fit: cover;
}
.image img{
  margin-right: 2rem;
}

/* Section Hero */
.section-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4rem 2rem;
}

.hero-content {
  flex: 1;
  padding: 2rem;
  max-width: 600px;
}

.hero-content p {
  font-size: 1.2rem;
}

.hero-content h1 {
  font-size: 2.5rem;
}

.hero-content .btn-group {
  margin-top: 1.5rem;
}

.hero-content .btn {
  padding: 10px 20px;
  font-size: 1.1rem;
  cursor: pointer;
}



.hero-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.section-hero{
  background-color: #a6c8fc;
  color: black;
  font-weight: 600;
}

/* Responsive Media Queries */
@media (max-width: 1024px) {
  .destination {
    display: none;
  }

  .section-hero .container {
    flex-direction: column;
    align-items: center;
  }

  .hero-content {
    text-align: center;
    padding: 1rem;
  }

  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .hero-image {
    margin-top: 2rem;
    width: 100%;
  }

  .hero-image img {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .hero-text h1 {
    font-size: 2rem;
  }

  .hero-text p {
    font-size: 1.2rem;
  }


  .grid-two-cols {
    flex-direction: column;
    align-items: center;
  }

  .hero-image {
    max-width: 100%;
  }

  .hero-content h1 {
    font-size: 1.8rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .hero-content .btn-group {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .hero-text h1 {
    font-size: 1.8rem;
  }

  .hero-text p {
    font-size: 1rem;
  }


  .hero-content h1 {
    font-size: 1.5rem;
  }

  .hero-content p {
    font-size: 0.9rem;
  }

  .hero-image img {
    max-width: 100%;
  }
}

/* home page close */

/* about page */
.about-heading{
  /* color: white; */
  color: black;
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
}

.about-para {
  color: blueviolet;
  font-size: 1.1rem;
  line-height: 1.6;
  padding: 10px;
}

/* About Section */
.about {
  background-color: white;
  /* background-color: black; */
  color: black;
  padding: 50px 20px;
}

.about-container {
  
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

/* About Content */
.about-parag{
}
.about-content {
  /* color:black; */
  max-width: 600px;
}

.welcome-message {
  text-align: center;
  font-size: 2.2rem;
color: brown;}

.about-heading {
  font-size: 2.3rem;
  margin: 20px 0;
}

.about-para {
  /* color: white; */
  margin-bottom: 20px;
  /* margin-left: 5rem; */
  font-size: 1.1rem;
}

/* Button Styles */
.button-group {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.btn, .about-btn {
  background-color: goldenrod;
  color: black;
  border: none;
  border-radius: 2rem;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, color 0.3s;
}

.btn:hover, .about-btn:hover {
  background-color: gold;
  border-radius: 2rem;
  color: rgb(14, 119, 204);
}

.about-btn {
  /* background-color: #333; */
}

/* Image Section */
.about-image2 img{
  width: 50vw;
  margin-top: 10rem;
  border-radius: 20%;
}


/* Responsive Design */
@media screen and (max-width: 768px) {
  .about-container {
    grid-template-columns: 1fr;
  }

  .button-group {
    flex-direction: column;
    gap: 15px;
  }

  .btn, .about-btn {
    width: 100%;
  }
  .about-image2{
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .about-heading {
    font-size: 1.5rem;
  }

  .about-para {
    font-size: 1rem;
  }
  .about-image2{
    display: none;
  }
}



/* about close */

/* service */
/* General styling for the service section */
.service {
  /* background-color: black; */
  background-color: white;
  color: rgb(14, 10, 10);
  padding: 2rem;
  
  /* background-color: #020202; */
}

.service-heading1{
  text-shadow: 2px 2px;
  font-size: 2.1rem;
  /* text-align: center; */
  white-space: nowrap;
  position: relative;
  animation: moveHeading 11s linear infinite;

}
@keyframes moveHeading {
  0% {
    transform: translateX(-80%);
  }
  100% {
    transform: translateX(80%);
  }
}

.card-image1{
  width: 100%;
  height: 100%;
}
.service-cards-container{
  display: flex;
  justify-content:space-evenly;
  align-items: center;
  text-align: center;
}

.service-heading {
  margin-top: 2rem;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

/* Container for all cards */
.service-container {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  padding: 0 1rem;
}

/* Styling for each card */
.card {
  background-color: white;
  color: black;
  /* background-color: black; */
  /* color: white; */
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

/* Card details section */
.card-details {
  padding: 1rem;
}

.grid {
  display: grid;
  gap: 0.5rem;
}

.grid-two-cols {
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

/* For small screens (mobiles) */
 @media (max-width: 768px) {
  .service-heading {
    font-size: 1.5rem;
  }
  .card-details{
    display: none;
  }
  .service-container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .service-cards-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
  }

  .grid-two-cols {
    grid-template-columns: 1fr;
    text-align: center;
  }
} 

/* For even smaller screens (very mobile-friendly) */
 @media (max-width: 480px) {
  .service-heading {
    font-size: 1.2rem;
    text-align: center;
  }
  .card-details{
    display: none;
  }
.service-container{
  display: flex;
  
  justify-content: space-between;

}
  .grid-two-cols p {
    font-size: 0.9rem;
    text-align: center;
  }
} 

.service-destination {
  /* text-align: center; */
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
  gap: 20px; /* space between cards */
}

.service-card {
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateX(-10px); /* Slight hover effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-title {
  font-size: 1.2rem;
  margin: 10px;
  text-align: center;
}

.card-description {
  font-size: 1rem;
  color: darkred;
  padding: 0 10px 10px;
}
.num{
  font-size: 16px;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .service-destination {
    grid-template-columns: 1fr 1fr; /* 2 cards per row on tablets */
  }
}

@media (max-width: 480px) {
  .service-destination {
    grid-template-columns: 1fr; /* 1 card per row on mobile */
  }
}

/* end service */

/* footer start */
.footer{
  padding: 4rem 6rem;
  background-color: black;
  color: white;

}
.top{
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.top i{
  color: white;
  margin-left: 1rem;
  font-size: 2rem;
}
.top i:hover{
  color: aqua;
}
.bottom{
  padding-top: 2rem;
  text-align: start;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.bottom div{
  display: flex;
  flex-direction: column;
}
.bottom h4{
  font-size: 1.3rem;
  padding:1rem 0 .8rem 0;
}
.bottom a{
  text-decoration: none;
  color: #fafafa;
  padding-bottom: .4rem;
  font-size: 1.1rem;
}
@media screen and(max-width:850px){
  .footer{
      padding: 4rem 2rem;
  }
  .footer i{
      margin: 1rem 1rem 0 0;
  }
  .bottom div{
      width: 50%;
  }
}
/* footer end */


.destination {
  text-align: center;
  padding: 20px;
}

.destination h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.destination p {
  font-size: 18px;
  margin-bottom: 40px;
}

/* Flex container for each destination */
.first-des, .first-des-reverse {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.first-des-reverse {
  flex-direction: row-reverse;
}

/* Style for text blocks */
.des-text {
  flex: 1;
  padding: 20px;
  max-width: 50%;
}

.des-text h2 {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.des-text p {
  font-size: 1.5rem;
  line-height: 1.5;
}

/* Image styling */
.image {
  flex: 1;
  max-width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Spinning effect for images */
.image img {
  /* animation: spin 10s infinite linear; */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


 /* registration form */
 .section-registration {
  padding: 20px;
  background-color: #f4f4f4;
}

.container-r {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.registration-image img {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.registration-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.registration-form h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.registration-form div {
  margin-bottom: 15px;
}

.registration-form label {
  font-size: 16px;
  margin-bottom: 5px;
  color:white;
}

.registration-form input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.registration-form input:focus {
  border-color: #007bff;
  outline: none;
}

.rbtn {
  background-color: #007bff;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  background-color: #0056b3;
}

/* Responsive Styles */
@media screen and (min-width: 768px) {
  .container-r {
    grid-template-columns: 1fr 1fr; /* Two columns on tablet/desktop */
    align-items: center;
  }

  .registration-image img {
    width: 90%;
    max-width: 500px;
  }

  .registration-form {
    padding: 30px;
  }
}

@media screen and (max-width: 767px) {
  .registration-form h1 {
    font-size: 1.2rem;
    /* text-align: start; */
  }

  .btn-submit {
    width: 100%;
  }
}
 /* end registration */

 /* error page  */
#error-page {
  display: flex;
  align-items: center;
  justify-content: center;
}
#error-page .content {
  max-width: 70rem;
  text-align: center;
}
.content h2.header {
  font-size: 18vw;
  line-height: 1em;
  animation: animate 10s ease-in-out infinite;
  background: -webkit-repeating-linear-gradient(
    -60deg,
    #71b7e6,
    #b98acc,
    #ee8176,
    #b98acc,
    #9b59b6
  );
  background-size: 500%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25);
  position: relative;
  -webkit-animation: animate 10s ease-in-out infinite;
}

@keyframes animate {
  0% {
    background-position: 0 0;
  }
  25% {
    background-position: 100% 0;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
.content h4 {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 2rem;
}

.content .btns {
  margin: 25px 0;
  display: inline-flex;
  justify-content: center;
}
.content .btns a {
  display: inline-block;
  margin: 0 10px;
  text-decoration: none;
  border: 2px solid black;
  color: goldenrod;
  font-weight: 500;
  padding: 10px 25px;
  border-radius: 25px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.content .btns a:hover {
  background: gold;
  color:black;
}
/* error close */

/* login  */
.login-f {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fafafa;
}

.login-container {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
}

/* Grid Layout for larger screens */
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  align-items: center;
}

.login-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Form section */
.login-form {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.login-heading {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

label {
  font-size: 1rem;
  color: #555;
  margin-bottom: 5px;
  display: block;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

input[type="text"]:focus,
input[type="password"]:focus {
  border-color: #007bff;
  outline: none;
}

.login-button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
}

/* Responsive styles */
@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .login-image img {
    display: none;
  }

  .login-form {
    padding: 20px;
  }

  .login-heading {
    font-size: 1.5rem;
  }

  label {
    font-size: 0.9rem;
  }

  input[type="text"],
  input[type="password"],
  button {
    font-size: 0.9rem;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .login-heading {
    font-size: 1.25rem;
  }

  input[type="text"],
  input[type="password"],
  button {
    font-size: 1rem;
    padding: 12px;
  }
}
/* login end */
/* contact */

/* Contact Form Section */
.section-contact {
  padding: 50px 0;
}

.contact-content {
  text-align: center;
}

.contact-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.grid {
  display: grid;
  gap: 20px;
}

.grid-two-colsC {
  grid-template-columns: 1fr 1fr;
}

/* Contact Form */
.contact-form {
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
  padding: 20px;
  border-radius: 8px;
}

.contact-div {
  margin-bottom: 15px;
}

.contact-div label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.contact-div input,
.contact-div textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.btn-cbutton {
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-cbutton:hover {
  background-color: #555;
}

/* Map section */
.map-container {
  margin-top: 30px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .grid-two-colsC {
    grid-template-columns: 1fr; /* Stack columns on smaller screens */
  }

  .contact-img img {
    width: 100%; /* Ensure the image scales */
    height: auto;
  }

  .contact-heading {
    font-size: 2rem; /* Smaller heading for mobile */
  }
}

@media (max-width: 480px) {
  .contact-form {
    padding: 10px; /* Reduce padding on very small screens */
  }

  .contact-div input,
  .contact-div textarea {
    font-size: 0.9rem; /* Adjust font size */
  }

  .btn-cbutton {
    width: 100%; /* Make the button full width on small screens */
  }
}
/* end contact */

